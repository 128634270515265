import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

import Checkout from '../components/checkout'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <h1>Hi people</h1>
    <p>Welcome to the Webhosting.coop  Gear site.</p>
    <p>Now go buy something certified by our in-house refurbishing program.</p>
    <p>This is a test. Only test transactions at the moment!</p>
    <p>
      Follow{' '}
      <a href="https://www.gatsbyjs.org/tutorial/ecommerce-tutorial/">
        this tutorial
      </a>{' '}
      to build your own.
    </p>
    <Checkout />
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/">Go back to the storefront.</Link>
  </Layout>
)

export default IndexPage
